/* * {
  font-family: "Inter", sans-serif !important;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* CSS for Custom Scrollbar */

/* width of scrollbar*/
.uploadPorjectPopUpMain::-webkit-scrollbar {
  width: 10px;
}

.myProjectScrollbar::-webkit-scrollbar {
  height: 7px;
}

.tableUploadPopUp::-webkit-scrollbar {
  width: 7px;
}

.versionOverflow::-webkit-scrollbar {
  width: 5px;
}

.pdfFileViewer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.myProjectScrollbar::-webkit-scrollbar-track,
.uploadPorjectPopUpMain::-webkit-scrollbar-track,
.tableUploadPopUp::-webkit-scrollbar-track,
.versionOverflow::-webkit-scrollbar-track,
.pdfFileViewer::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.myProjectScrollbar::-webkit-scrollbar-thumb,
.uploadPorjectPopUpMain::-webkit-scrollbar-thumb,
.tableUploadPopUp::-webkit-scrollbar-thumb,
.versionOverflow::-webkit-scrollbar-thumb,
.pdfFileViewer::-webkit-scrollbar-thumb {
  background: rgba(221, 221, 231, 1);
  border-radius: 9999px;
}

.timestampDisplayTable th>p,
.timestampDisplayTable td>p,
.timestampDisplayTable tr>p {
  font-size: 14px;
}

.color-black {
  color: black;
}

.hamburger {
  display: none;
}

.drawerLogo {
  display: none !important;
}

/* Audio Player Settings */
.rhap_container {
  background: rgba(244, 247, 254, 1) !important;
  padding: 30px 20px !important;
  border-radius: 10px !important;
}

.rhap_progress-indicator {
  background: rgba(17, 17, 187, 1) !important;
}

.rhap_progress-filled {
  background: rgba(17, 17, 187, 1) !important;
}

.rhap_download-progress {
  background: white !important;
}

button.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button {
  color: rgba(17, 17, 187, 1);
  position: relative;
}

button.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
  color: rgba(172, 172, 185, 1);
}

button.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button {
  color: rgba(172, 172, 185, 1);
}

.rhap_main {
  flex-direction: column-reverse !important;
}

.rhap_controls-section {
  justify-content: flex-end !important;
  margin-bottom: 10px;
}

.rhap_additional-controls {
  display: block !important;
}

.rhap_volume-controls {
  display: none !important;
}

@media (max-width: 1600px) {

  .timestampDisplayTable td,
  .timestampDisplayTable th {
    padding: 10px 5px !important;
  }
}